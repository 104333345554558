<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="1000"
      :visible="visible"
      :confirm-loading="loading"
      title="资产借用"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        class="form"
        ref="form"
        :model="form"
        :rules="rules"
        layout="vertical"
      >
        <a-row :gutter="16">
          <a-col :md="8" :sm="24" :xs="24">
            <a-form-item label="申请人" name="applicantName">
              <a-input
                v-model:value="form.applicantName"
                placeholder="请输入申请人"
                allow-clear
                :disabled="true"
                class="disabledColor"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-form-item label="申请单位" name="departmentName">
              <a-input
                v-model:value="form.departmentName"
                placeholder="请输入申请单位"
                allow-clear
                :disabled="true"
                class="disabledColor"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-form-item label="申请部门" name="departmentName">
              <a-input
                v-model:value="form.departmentName"
                placeholder="请输入申请部门"
                allow-clear
                :disabled="true"
                class="disabledColor"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-form-item label="公物仓" name="warehouseIdNew">
              <a-select
                allow-clear
                placeholder="请选择公物仓"
                v-model:value="form.warehouseIdNew"
              >
                <a-select-option
                  v-for="item in warehouseList"
                  :key="item.warehouseId"
                  :value="item.warehouseId"
                >
                  {{ item.unitName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24" :xs="24">
            <a-form-item label="备注" name="remark">
              <a-input
                v-model:value="form.remark"
                placeholder="请输入备注"
                allow-clear
                :disabled="disabled"
                class="disabledColor"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <div class="ud-text-left">资产列表</div>
        <div class="ud-text-right">
          <a-space>
            <a-button @click="addAssets()">选择资产</a-button>
          </a-space>
        </div>
      </div>
      <ud-pro-table
        ref="table"
        row-key="assetsId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
        :toolbar="false"
      >
        <template #action="{ index }">
          <a-space>
            <a @click="remove(index)">移除</a>
          </a-space>
        </template>
      </ud-pro-table>
    </a-modal>
    <assetsInfoSelected
      v-model:visible="showSelected"
      @done="setFormData"
      v-model:selected="formData"
      :category-data="categoryData"
      :filter="filter"
    />
  </div>
</template>

<script>
import XLSX from 'xlsx'
import * as formInfoApi from '@/api/ams/formInfo'
import assetsInfoSelected from '../assets/selected.vue'
import setting from '@/config/setting'

export default {
  name: 'warehousingForm',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部机构
    organizationList: Array,
    // 全部公物仓
    warehouseList: Array
  },
  components: {
    assetsInfoSelected
  },
  data() {
    return {
      active: 'info',
      categoryData: [],
      disabled: false,
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data),
      filter: { state: 'idle' },
      // 编辑弹窗表单验证规则
      rules: {
        warehouseIdNew: [
          {
            required: true,
            message: '请选择公物仓',
            type: 'number',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      pointPhotoList: [],
      previewImage: '',
      previewVisible: false,
      departmentList: [],
      userList: [],
      departmentList2: [],
      userList2: [],
      formData: [],
      showSelected: false,
      columns: [
        {
          width: 48,
          align: 'center',
          fixed: 'left',
          customRender: ({ index }) => index + 1
        },
        {
          title: '资产编号',
          width: 100,
          align: 'center',
          fixed: 'left',
          dataIndex: 'assetsCode',
          sorter: true
        },
        {
          title: '资产名称',
          width: 100,
          align: 'center',
          dataIndex: 'assetsName',
          sorter: true
        },
        {
          title: '分类编码',
          width: 100,
          align: 'center',
          dataIndex: 'categoryCode',
          sorter: true
        },
        {
          title: '品牌',
          align: 'center',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '型号',
          align: 'center',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count',
          sorter: true
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'unitPrice',
          sorter: true
        },
        {
          title: '金额',
          align: 'center',
          dataIndex: 'total',
          sorter: true
        },
        {
          title: '取得时间',
          align: 'center',
          dataIndex: 'buyDate',
          sorter: true,
          customRender: ({ text }) =>
            this.$util.toDateString(text, 'yyyy-MM-dd')
        },
        {
          title: '所属单位',
          align: 'center',
          dataIndex: 'affiliatedUnitName',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 100,
          align: 'center',
          fixed: 'right',
          slots: { customRender: 'action' }
        }
      ],
      // 选中数据
      selection: [],
      // 导入的数据
      importData: [],
      // 导入数据的列
      importTitle: [
        '序号',
        '资产编号',
        '资产名称',
        '分类编码',
        '品牌',
        '型号',
        '数量',
        '单价',
        '金额',
        '购置时间',
        '现状'
      ],
      // 导入数据二维数组形式
      importDataAoa: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        this.isUpdate = true
      } else {
        this.form = Object.assign({}, this.data, {
          applicantName: this.$store.state.user.user.nickname,
          unitName: this.$store.state.user.user.unitName,
          departmentName: this.$store.state.user.user.organizationName
        })
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    /* 导出excel */
    exportBas() {
      const array = [
        [
          '序号',
          '所属单位',
          '资产编号',
          '资产名称',
          '分类编码',
          '品牌',
          '规格型号',
          '原值',
          '取得日期'
        ]
      ]
      this.$util.exportSheet(XLSX, array, '资产清单')
    },
    /* 导入本地excel文件 */
    importFile(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, {
          type: 'array',
          cellDates: true
        })
        const sheetNames = workbook.SheetNames
        const worksheet = workbook.Sheets[sheetNames[0]]
        // 解析成二维数组
        const aoa = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        // 生成表格需要的数据
        const list = []
        let maxCols = 0
        const title = []
        aoa.forEach((d) => {
          if (d.length > maxCols) {
            maxCols = d.length
          }
          const row = {}
          for (let i = 0; i < d.length; i++) {
            const key = this.getCharByIndex(i)
            row[key] = d[i]
            row['__colspan__' + key] = 1
            row['__rowspan__' + key] = 1
          }
          list.push(row)
        })
        for (let i = 0; i < maxCols; i++) {
          title.push(this.getCharByIndex(i))
        }
        this.importTitle = title
        this.importData = list
        this.importDataAoa = aoa
        console.log('importData2', this.importData)
        console.log('importDataAoa', this.importDataAoa)
      }
      reader.readAsArrayBuffer(file)

      return false
    },
    /* 生成Excel列字母序号 */
    getCharByIndex(index) {
      const chars = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ]
      if (index < chars.length) {
        return chars[index]
      }
      const n = parseInt(index / chars.length)
      const m = index % chars.length
      return chars[n] + chars[m]
    },
    /* 上传 */
    doUpload({ file }) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      this.$http
        .post('/ams/assetsInfo/import', formData)
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.formData = res.data.data
            this.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
      return false
    },
    datasource(option, callback) {
      callback(this.formData, this.formData.count)
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    },
    /* 保存编辑 */
    save() {
      if (this.formData == null || this.formData.length === 0) {
        this.$message.error('资产列表不能为空')
        return
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.formData) {
            this.form.assetsData = this.formData
          }
          this.form.flowId = 3
          formInfoApi
            .save(this.form, false)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.form = {}
                this.form.applicantName = this.$store.state.user.user.username
                this.form.unitName = this.$store.state.user.user.unitName
                this.form.departmentName =
                  this.$store.state.user.user.organizationName
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    addAssets() {
      this.showSelected = true
    },
    setFormData(items) {
      this.formData = items
      this.reload()
    },
    remove(index) {
      this.formData.splice(index, 1)
      this.reload()
    }
  }
}
</script>

<style scoped lang='less'>
.form {
  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;
    input[disabled],
    select[disabled],
    span {
      color: black;
    }
    .ant-input-disabled,
    .ant-select-selector {
      color: black;
      background: #fff;
    }
  }
}
</style>
